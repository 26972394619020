<template>
  <div class="container p-0">
    <b-overlay
      :show="ShowRound"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <header>

        <marquee scrollamount="5"> <i class="far fa-bullhorn fa-1x" /> ยินดีต้อนรับทุกท่านเข้าสู่ หวย
          เว็บไซต์ซื้อหวยออนไลน์อันดับ 1 ของไทย เรามีให้บริการทั้ง หวยรัฐบาล หวยลาว หวยมาเลย์ หวยฮานอย
          หวยหุ้นไทยและต่างประเทศ หวยยี่กี 264 รอบ พร้อมให้สมาชิกทุกท่านได้ร่วมสนุก ฝาก-ถอน รวดเร็ว ทันใจ ยินดีให้บริการตลอด
          24 ชั่วโมง ค่ะ <i class="far fa-bullhorn fa-flip-horizontal" /></marquee>

        <div class="row text-center pr-1 pl-1">
          <div class="col-6 d-flex align-items-center text-white action-bar">
            <img
              src="assets/ico/thb_icon.png"
              alt="thb"
              height="20"
              width="20"
            >&nbsp;
            <small> {{
              Commas(this.$store.state.appConfig.UserData.MainWallet)
            }}</small>
          </div>
          <div
            class="col-6 d-flex align-items-center justify-content-end text-white action-bar"
            @click="contactus()"
          >
            <i
              class="fab fa-line"
              style="color: #37BB22"
            />&nbsp;
            <small>ติดต่อเรา</small>
          </div>
        </div>
      </header>

      <div
        v-if="rule != null"
        @click="rule = null"
      >
        <h4 class="text-danger cursor-pointer mt-1">
          <i class="fas fa-chevron-circle-left fa-lg" />
          ย้อนกลับ
        </h4>
      </div>

      <!-- กติกาหวย -->
      <div>
        <div v-if="rule">
          <h2 class="text-center text-danger mt-1">
            ระบบปิดรับแทง
          </h2>
          <!-- <p>ปิดรับ  12/07/2565 10:55:00</p> -->
        </div>

        <t-h-g v-if="rule === 'หวยรัฐบาล'" />
        <g-s-b v-if="rule === 'หวยออมสิน'" />
        <t-k-s v-if="rule === 'หวย ธกส'" />
        <t-h-z v-if="rule === 'หวยหุ้นไทย'" />
        <y-g v-if="rule === 'หวยยี่กี'" />
        <d-e v-if="rule === 'เยอรมัน'" />
        <e-g v-if="rule === 'อียิปต์'" />
        <g-b v-if="rule === 'อังกฤษ'" />
        <l-a v-if="rule === 'ลาว'" />
        <j-p v-if="rule === 'หุ้นนิเคอิ'" />
        <s-g v-if="rule === 'สิงคโปร์'" />
        <t-w v-if="rule === 'ไต้หวัน'" />
        <c-n v-if="rule === 'จีน'" />
        <i-n v-if="rule === 'อินเดีย'" />
        <r-u v-if="rule === 'รัสเซีย'" />
        <v-n v-if="rule === 'ฮานอย'" />
        <h-k v-if="rule === 'ฮั่งเส็ง'" />
        <m-l v-if="rule === 'มาเลย์'" />
        <k-r v-if="rule === 'หุ้นเกาหลี'" />
      </div>
      <!-- ปิดกติกาหวย -->
      <div
        v-if="SubItems.length <= 0 && rule == null"
        class="container pad-inner"
      >
        <div
          v-for="item in Items"
          :key="item._id"
          class="section-wrap"
        >
          <div class="section-title-l"> <strong class="float-left">{{ item[0].LottoGroupName }}</strong>
            <div class="clearfix" />
          </div>
          <div class="row gutters-10">
            <div
              v-for="LottoType in item"
              :key="LottoType._id"
              class="col-md-6 col-lg-4"
            >
              <div
                data-href="/member/lottery/government"
                :class="`card-items cursor-pointer ${
                  LottoType.ShowCloseDate ||
                  (LottoType.LottoSubType === 1 && LottoType.LottoSubHead
                    ? LottoType.LottoSubHead.some((el) => el.ShowCloseDate)
                    : false)
                    ? 'open'
                    : 'closed'
                }`"
                @click="RoutTang(LottoType)"
              >
                <div class="card-img">
                  <!-- <img src="/assets/themes/front2021/images/thai_lottery_l.png"> -->
                  <img :src="`/assets/themes/front2021/images/${LottoType.picture}`">
                </div>
                <div class="card-detail">
                  <div class="font-heading font-sub-heading">
                    <strong>{{ LottoType.LottoName }} </strong>
                  </div>
                  <div class="detail-time">
                    <div class="d-flex align-items-center">
                      <i
                        class="fa-solid fa-stopwatch color-sub"
                        style="font-size: 16px"
                      />
                      <strong
                        v-if="LottoType.ShowCloseDate && LottoType.LottoSubType === 0"
                        class="text-time"
                        style="margin-left: 5px;"
                      >
                        {{ LottoType.ShowCloseDate }}
                      </strong>
                      <strong
                        v-else-if="(LottoType.LottoSubType === 1 && LottoType.CloseDate)"
                        class="text-time"
                      >
                        &nbsp; 24 ชั่วโมง
                      </strong>
                      <strong
                        v-else
                        style="font-size: 22px; margin-left: 5px;"
                        class="color-sub"
                      >
                        ปิดรับแทง
                      </strong>
                    </div>
                    <div
                      v-if="LottoType.LottoSubType === 1"
                      class="font-sub-title color-sub"
                    >
                      &nbsp;จำนวนรอบ : {{ LottoType.SubheadLength }} รอบ
                    </div>
                    <div
                      v-else
                      class="font-sub-title color-sub"
                    >
                      ปิดรับ : {{ LottoType.CloseDateName }} {{ LottoType.CloseBet }}
                    </div>
                  </div>
                </div>
                <div class="card-go">
                  <i class="fa-solid fa-arrow-right ico" />
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- end section-->
      </div>

      <!-- หวยย่อยยย -->
      <div v-if="SubItems.length > 0">
        <div @click="SubItems=[]">
          <h4 class="text-danger cursor-pointer mt-1">
            <i class="fas fa-chevron-circle-left fa-lg" />
            ย้อนกลับ
          </h4>
        </div>

        <div class="lotto">
          {{ SubItems[0].LottoHeadName }}
        </div>

        <div class="depo">
          <div class="row gutters-10">
            <div
              v-for="LottoSubType in SubItems"
              :key="LottoSubType._id"
              class="col-md-6 col-lg-4"
            >
              <div
                class="card-items card-s"

                @click="RoutTang(LottoSubType)"
              >
                <div class="card-round">
                  <!-- <div class="color-sub">
                    <strong>รอบที่</strong>
                  </div> -->
                  <div class="font-heading">
                    <strong>{{ LottoSubType.LottoName }}</strong>
                  </div>
                </div>
                <div class="card-detail cursor-pointer">
                  <div class="detail-time">
                    <div class="d-flex align-items-center">
                      <i
                        class="fa-solid fa-stopwatch color-sub"
                        style="font-size: 16px"
                      /><strong
                        style="font-size: 17.5px"
                        class="text-time"
                        :class="`${
                          LottoSubType.ShowCloseDate ? '': 'disable'
                        }`"
                      >&nbsp;{{ LottoSubType.ShowCloseDate ? LottoSubType.ShowCloseDate : 'ปิดรับแทง' }}</strong>

                    </div>
                    <div class="font-sub-title color-sub">
                      ปิดรับ : {{ LottoSubType.CloseDateName }} {{ LottoSubType.CloseBet }}
                    </div>
                  </div>
                </div>
                <div class="card-go">
                  <i class="fa-solid fa-arrow-right ico" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-overlay>
  </div>
</template>

<script>
import {
  // BRow,
  // BCol,
  BOverlay,
  BIconController,
  // BBadge,
  // BInputGroup,
  // BInputGroupPrepend,
  // BButton,
  // BFormInput,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'
import ML from '../rule/ml.vue'
import THG from '../rule/thg.vue'
import GSB from '../rule/gsb.vue'
import TKS from '../rule/tks.vue'
import YG from '../rule/yg.vue'
import DE from '../rule/de.vue'
import GB from '../rule/gb.vue'
import LA from '../rule/la.vue'
import JP from '../rule/jp.vue'
import SG from '../rule/sg.vue'
import TW from '../rule/tw.vue'
import CN from '../rule/cn.vue'
import IN from '../rule/in.vue'
import RU from '../rule/ru.vue'
import VN from '../rule/vn.vue'
import EG from '../rule/eg.vue'
import KR from '../rule/kr.vue'
import HK from '../rule/hk.vue'
import THZ from '../rule/thz.vue'

export default {
  components: {
    // BRow,
    // BCol,
    BOverlay,
    BIconController,
    ML,
    THG,
    TKS,
    YG,
    EG,
    DE,
    GB,
    LA,
    SG,
    TW,
    CN,
    JP,
    IN,
    RU,
    VN,
    GSB,
    HK,
    THZ,
    KR,
    // BBadge,
    // BInputGroup,
    // BInputGroupPrepend,
    // BButton,
    // BFormInput,
  },
  data() {
    return {
      rule: null,
      Test: false,
      ShowRound: true,
      message: '',
      GetRoundInterval: null,
      Interval: null,
      Items: [],
      SubItems: [],
      AgentData: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.GetRoundInterval)
  },
  async mounted() {
    this.ShowWallet()
    this.ShowAgent()
    await this.GetSublist()
    this.ShowRound = false
    this.LoopGetData()
    await this.LoopCloseTime()

    this.GetRoundInterval = setInterval(() => {
      // this.LoopGetData()
    }, 30000)
    this.Interval = setInterval(() => {
      this.LoopCloseTime()
    }, 1000)
  },
  methods: {
    contactus() {
      window.location.href = 'https://line.me/R/ti/p/@300dtopn'
    },
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://ag3api.linelotto.vip/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get(
          'https://ag3api.linelotto.vip/api/LottoList',
        )
        const grouped = await this.groupBy(Res, group => group.group_id)
        const GroupArr = Object.fromEntries(grouped)
        // console.log(GroupArr)
        // eslint-disable-next-line
        for (const key in GroupArr) {
          const Group = GroupArr[key]
          // console.log(Group)
          const GroupLotto = Group.map(item => ({
            LottoName: item.name,
            LottoHead: item.HeadID,
            picture: item.picture,
            LottoSubHead: item.Subhead.length > 0 ? item.Subhead.map(Subtem => ({
              LottoHeadName: item.name,
              LottoName: Subtem.name,
              LottoHead: item.HeadID,
              LottoSubHead: Subtem.SubHeadID,
              LottoGroupID: item.group_id,
              LottoGroupName: item.group_name,
              CloseBet: '',
              CloseDate: '',
              CloseDateName: '',
              ShowCloseDate: '',
              ShowCloseDateName: '',
            })) : null,
            SubheadLength: item.Subhead.length,
            LottoSubType: item.subtype,
            LottoGroupID: item.group_id,
            LottoGroupName: item.group_name,
            CloseBet: item.StopBetTime,
            CloseDate: item.CloseDate,
            CloseDateName: item.CloseDateName,
            ShowCloseDate: '',
            ShowCloseDateName: '',
          }))
          List.push(GroupLotto)
        }
        // console.log(List)
        this.Items = List
      } catch (e) {
        console.log(e)
      }
    },
    async LoopGetData() {
      // eslint-disable-next-line
      for (const K in this.Items) {
        // console.log(K)
        // eslint-disable-next-line
        for (const K2 in this.Items[K]) {
          const Item = this.Items[K][K2]
          // console.log(Item)
          if (Item.LottoSubType === 0) {
            // eslint-disable-next-line no-await-in-loop
            const Data = await this.GetData(Item.LottoHead, Item.LottoSubHead)
            // console.log()
            if (Data) {
              Item.CloseBet = Data.StopBetTime
              Item.CloseDate = Data.CloseDate
              Item.CloseDateName = Data.CloseDateName
            }
          } else if (Item.LottoSubType === 1) {
            // eslint-disable-next-line
            for (const K3 in Item.LottoSubHead) {
              const Item2 = Item.LottoSubHead[K3]
              // eslint-disable-next-line no-await-in-loop
              const Data = await this.GetData(
                Item2.LottoHead,
                Item2.LottoSubHead,
              )
              // console.log()
              if (Data) {
                Item2.CloseBet = Data.StopBetTime
                Item2.CloseDate = Data.CloseDate
                Item2.CloseDateName = Data.CloseDateName
              }
            }
          }
        }
      }
      this.LoopCloseTime()
      // console.log(this.Items)
    },
    // eslint-disable-next-line consistent-return
    async GetData(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://ag3api.linelotto.vip/api/round/ShowByHeadAndSub',
          { params },
        )

        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        // console.log(e)
        return null
      }
    },
    LoopCloseTime() {
      // eslint-disable-next-line
      for (const K in this.Items) {
        // console.log(this.Items)
        // eslint-disable-next-line
        for (const K2 in this.Items[K]) {
          const Item = this.Items[K][K2]
          if (Item.LottoSubType === 0) {
            if (Item.CloseDate) {
              Item.ShowCloseDate = this.CalDiffTime(Item.CloseDate)
            }
          } else if (Item.LottoSubType === 1) {
            if (Item.CloseDate) {
              Item.ShowCloseDate = this.CalDiffTime(Item.CloseDate)
              // eslint-disable-next-line
              for (const K3 in Item.LottoSubHead) {
                const Item2 = Item.LottoSubHead[K3]
                if (Item2.CloseDate) {
                  Item2.ShowCloseDate = this.CalDiffTime(Item2.CloseDate)
                }
              }
            } else {
              // eslint-disable-next-line
              for (const K3 in Item.LottoSubHead) {
                const Item2 = Item.LottoSubHead[K3]
                if (Item2.CloseDate) {
                  Item2.ShowCloseDate = this.CalDiffTime(Item2.CloseDate)
                }
              }
            }
          }
        }
      }
    },
    RoutTang(Lotto) {
      this.rule = null
      // console.log(Lotto.LottoName)
      // console.log(Lotto.LottoHead)
      const Type = btoa(JSON.stringify(
        {
          LottoHead: Lotto.LottoHead,
          LottoSubHead: Lotto.LottoSubHead && Number.isInteger(Lotto.LottoSubHead) ? Lotto.LottoSubHead : null,
        },
      ))
      if (Lotto.LottoSubType === 1) {
        // console.log(Lotto)
        if (Lotto.LottoSubHead && Lotto.CloseDateName) {
          this.SubItems = Lotto.LottoSubHead
        } else {
          this.showToast('ยังไม่เปิดรับแทงค่ะ')
          // console.log(Lotto.LottoName)
          if (Lotto.LottoName === 'หวยรัฐบาล') {
            this.rule = 'หวยรัฐบาล'
          } else if (Lotto.LottoName === 'หวยออมสิน') {
            this.rule = 'หวยออมสิน'
          } else if (Lotto.LottoName === 'หวย ธกส') {
            this.rule = 'หวย ธกส'
          } else if (Lotto.LottoName === 'หวยหุ้นไทย (เช้า)') {
            this.rule = 'หวยหุ้นไทย'
          } else if (Lotto.LottoName === 'หวยหุ้นไทย (เที่ยง)') {
            this.rule = 'หวยหุ้นไทย'
          } else if (Lotto.LottoName === 'หวยหุ้นไทย (บ่าย)') {
            this.rule = 'หวยหุ้นไทย'
          } else if (Lotto.LottoName === 'หวยหุ้นไทย (เย็น)') {
            this.rule = 'หวยหุ้นไทย'
          } else if (Lotto.LottoName === 'เยอรมัน') {
            this.rule = 'เยอรมัน'
          } else if (Lotto.LottoName === 'อังกฤษ') {
            this.rule = 'อังกฤษ'
          } else if (Lotto.LottoName === 'ลาว') {
            this.rule = 'ลาว'
          } else if (Lotto.LottoName === 'หุ้นนิเคอิ (เช้า)') {
            this.rule = 'หุ้นนิเคอิ'
          } else if (Lotto.LottoName === 'หุ้นนิเคอิ (บ่าย)') {
            this.rule = 'หุ้นนิเคอิ'
          } else if (Lotto.LottoName === 'สิงคโปร์') {
            this.rule = 'สิงคโปร์'
          } else if (Lotto.LottoName === 'ไต้หวัน') {
            this.rule = 'ไต้หวัน'
          } else if (Lotto.LottoName === ' จีน (เช้า)') {
            this.rule = 'จีน'
          } else if (Lotto.LottoName === ' จีน (บ่าย)') {
            this.rule = 'จีน'
          } else if (Lotto.LottoName === 'อินเดีย') {
            this.rule = 'อินเดีย'
          } else if (Lotto.LottoName === 'รัสเซีย') {
            this.rule = 'รัสเซีย'
          } else if (Lotto.LottoName === 'ฮานอย') {
            this.rule = 'ฮานอย'
          } else if (Lotto.LottoName === 'ฮั่งเส็ง (เช้า)') {
            this.rule = 'ฮั่งเส็ง'
          } else if (Lotto.LottoName === 'ฮั่งเส็ง (บ่าย)') {
            this.rule = 'ฮั่งเส็ง'
          } else if (Lotto.LottoName === 'มาเลย์') {
            this.rule = 'มาเลย์'
          } else if (Lotto.LottoName === 'อียิปต์') {
            this.rule = 'อียิปต์'
          } else if (Lotto.LottoName === 'หวยยี่กี') {
            this.rule = 'หวยยี่กี'
          } else if (Lotto.LottoName === 'หุ้นเกาหลี') {
            this.rule = 'หุ้นเกาหลี'
          }
        }
        return
      }
      if (Lotto.ShowCloseDate) {
        this.$router.push({
          name: 'tang',
          params: {
            Type,
          },
        })
      } else {
        // console.log(Lotto.LottoName)
        this.showToast('ยังไม่เปิดรับแทงค่ะ')
        if (Lotto.LottoName === 'หวยรัฐบาล') {
          this.rule = 'หวยรัฐบาล'
        } else if (Lotto.LottoName === 'หวยออมสิน') {
          this.rule = 'หวยออมสิน'
        } else if (Lotto.LottoName === 'หวย ธกส') {
          this.rule = 'หวย ธกส'
        } else if (Lotto.LottoName === 'หวยหุ้นไทย (เช้า)') {
          this.rule = 'หวยหุ้นไทย'
        } else if (Lotto.LottoName === 'หวยหุ้นไทย (เที่ยง)') {
          this.rule = 'หวยหุ้นไทย'
        } else if (Lotto.LottoName === 'หวยหุ้นไทย (บ่าย)') {
          this.rule = 'หวยหุ้นไทย'
        } else if (Lotto.LottoName === 'หวยหุ้นไทย (เย็น)') {
          this.rule = 'หวยหุ้นไทย'
        } else if (Lotto.LottoName === 'เยอรมัน') {
          this.rule = 'เยอรมัน'
        } else if (Lotto.LottoName === 'อังกฤษ') {
          this.rule = 'อังกฤษ'
        } else if (Lotto.LottoName === 'ลาว') {
          this.rule = 'ลาว'
        } else if (Lotto.LottoName === 'หุ้นนิเคอิ (เช้า)') {
          this.rule = 'หุ้นนิเคอิ'
        } else if (Lotto.LottoName === 'หุ้นนิเคอิ (บ่าย)') {
          this.rule = 'หุ้นนิเคอิ'
        } else if (Lotto.LottoName === 'สิงคโปร์') {
          this.rule = 'สิงคโปร์'
        } else if (Lotto.LottoName === 'ไต้หวัน') {
          this.rule = 'ไต้หวัน'
        } else if (Lotto.LottoName === ' จีน (เช้า)') {
          this.rule = 'จีน'
        } else if (Lotto.LottoName === ' จีน (บ่าย)') {
          this.rule = 'จีน'
        } else if (Lotto.LottoName === 'อินเดีย') {
          this.rule = 'อินเดีย'
        } else if (Lotto.LottoName === 'รัสเซีย') {
          this.rule = 'รัสเซีย'
        } else if (Lotto.LottoName === 'ฮานอย') {
          this.rule = 'ฮานอย'
        } else if (Lotto.LottoName === 'ฮั่งเส็ง (เช้า)') {
          this.rule = 'ฮั่งเส็ง'
        } else if (Lotto.LottoName === 'ฮั่งเส็ง (บ่าย)') {
          this.rule = 'ฮั่งเส็ง'
        } else if (Lotto.LottoName === 'มาเลย์') {
          this.rule = 'มาเลย์'
        } else if (Lotto.LottoName === 'อียิปต์') {
          this.rule = 'อียิปต์'
        } else if (Lotto.LottoName === 'หวยยี่กี') {
          this.rule = 'หวยยี่กี'
        } else if (Lotto.LottoName === 'หุ้นเกาหลี') {
          this.rule = 'หุ้นเกาหลี'
        }
      }
    },
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)
      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      return null
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    async ShowAgent() {
      try {
        const { data: response } = await this.$http.get(
          'https://ag3api.linelotto.vip/api/agent/show',
        )
        if (response && response.success === true) {
          this.AgentData = response.AgentData
        }
      } catch (err) {
        console.log(err)
      }
    },
    showToast(Mes) {
      this.$toast.error(Mes, {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fa fa-bell',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
/* * {
  padding: 0;
} */
.card-items.yigy {
  border: 2px solid #22529A !important;
}

.card-items.yigy-special {
  background-color: #3e7ddf4d;
  border: 2px solid #22529A !important;
}

.card-items.yigy264 {
  background-color: #3e7ddf4d;
  border: 2px solid #22529A !important;
}
.card-items.closed {
  background: #e1e1e1;
  border: 2px solid #22529A !important;
}

.card-items.open {
  background: #fe7c8762;
  border: 2px solid #22529A !important;
}
.action-bar, header, marquee {
  border-bottom: none;
  background: #22529A !important;
}
.pad-inner {
  padding: 20px 18px 100px;
}
.ico {
  margin-top: 9px;
  margin-left: 9px;
}
.card-go {
  background: rgba(255, 255, 255, 0.822);
  height: 32px;
  width: 32px;
  align-items: center;
  border-radius: 6px;
  margin-right: 10px;
}
.colum {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.boxhuay.disabled {
  filter: grayscale(1) opacity(0.7);
}
.boxhuay {
  width: 100%;
  font-family: 'Mitr', sans-serif;
  display: block;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
}
a .lotto-card {
  border: 2px solid #fff;
  font-family: 'Mitr', sans-serif;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px hsl(0deg 0% 100% / 50%);
}
.goldpattern {
  background: url(/images/bg-header.svg) top;
  background-size: 100%;
}
.goldpattern2 {
  background: url(/images/gold.jpg) top;
  background-size: 100%;
}
.head-huay {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.goldpattern {
  background-color: #052d2f;
}
.align-items-stretch {
  align-items: stretch !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.headtxt {
  text-align: right;
  color: #fff;
  text-shadow: #000 2px 0 0, #000 1.75517px 0.958851px 0,
    #000 1.0806px 1.68294px 0, #000 0.141474px 1.99499px 0,
    #000 -0.832294px 1.81859px 0, #000 -1.60229px 1.19694px 0,
    #000 -1.97998px 0.28224px 0, #000 -1.87291px -0.701566px 0,
    #000 -1.30729px -1.5136px 0, #000 -0.421592px -1.95506px 0,
    #000 0.567324px -1.91785px 0, #000 1.41734px -1.41108px 0,
    #000 1.92034px -0.558831px 0;
  letter-spacing: 0.04em;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
  font-size: 1.4rem;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.subhead-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: gold;
  text-shadow: #000 1px 1px 2px;
  font-size: 80%;
}
.date-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(166, 12, 12, 0.5);
  text-shadow: #000 1px 1px 2px;
}
.body-huay {
  width: 100%;
  display: block;
  background: #252525;
  text-align: right;
  color: #fff;
  padding: 8px 5px;
  font-size: 18px;
}
.bg-warning {
  background-color: #ff293e !important;
}
.headflag > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.headflag {
  width: 50px;
  overflow: hidden;
  align-self: stretch;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.fullflag {
  background-size: cover;
  height: 100%;
  width: 100%;
}
.lotto-head.lotto-yeekee[data-v-97f300d0] {
  background-color: #ce0a1e;
  border: 1px solid #aa1423;
}
.withdraw {
  max-width: 640px;
  margin: auto;
}
.depo {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 22px;
  background-color: #fff;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}

.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 8px;
  background: #272727;
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto-card2 {
  font-family: 'Mitr', sans-serif;
  /* border: 2px solid white; */
  border-radius: 12px;
  /* overflow: hidden; */
  /* box-shadow: rgb(255 255 255 / 50%) 0 0 10px; */
}
.lotto-card2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.huay-card-bg-yk {
  background: #f7bf46;
  color: #000000;
}
.huay-card-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 12px 6px;
  border-radius: 9px 9px 0px 0px;
  background: linear-gradient(180deg, #d66d43 0%, #d61c56 100%);
}
.huay-card-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  margin-top: 5px;
}

img {
  vertical-align: middle;
  border-style: none;
}
.huay-card-product-yk {
  border-radius: 7px;
  flex: 1;
  background-color: #fde14c;
}
.huay-card-body {
  background-color: #e2e3e4;
  width: 100%;
  padding: 6px;
  border-radius: 0px 0px 10px 10px;
}
.huay-card-body-content {
  background-color: #ffffff;
  color: var(--text-primary-color);
  width: 100%;
  border-radius: 10px;
  padding: 2px 3px;
}
.lotto-time {
  width: 100%;
  display: flex;
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  /* padding: 5px; */
  background: white;
  /* border: #ccc 1px solid; */
  /* border-top: none; */
  text-align: right;
  font-size: 16px;
  line-height: 30px;
  color: #bbb;
  flex-direction: row;
  justify-content: space-between;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.huay-card-product-name {
  font-size: 1.2rem;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  text-align: center;
  padding: 0px 1px;
  /* margin-top: 1px; */
  color: #fff;
}
.huay-card-period {
  float: right;
  background: white;
  color: #000;
  width: 95%;
  border-radius: 7px;
  text-align: center;
  font-size: 0.9rem;
  margin: 7px 5px;
}
</style>
